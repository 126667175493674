.overlay {
  background-color: rgba(255, 255, 255, 0.75);
  position: fixed;
  inset: 0px;
  z-index: 5100;
  overflow-y: auto;
}

.dialog-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 325px;
  max-width: 500px;
  z-index: 5101;
  background-color: var(--light-bg);
  color: var(--accent-text);
  border: 2px solid black;
  box-shadow: -3px 3px 0 0 black;
  padding: 25px;
  font-family: var(--ff-paragraph-3);
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 95vh;
  overflow-y: auto;

  @media (max-width: 480px) {
    gap: 10px;
  }

  .error {
    background-color: rgb(211, 108, 108);
  }

  h2 {
    font-size: var(--fs-400);
    margin: 0px;
    margin-right: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--accent-text);
    font-weight: bold;
  }

  & p {
    font-size: var(--fs-400);
    font-weight: 600;
    margin-block: 10px;
    @media (max-width: 480px) {
      font-size: var(--fs-300);
    }
  }

  & span {
    font-weight: 400;
  }

  & form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 480px) {
      gap: 15px;
    }

    & input {
      padding: 5px;
      border: 1px solid var(--accent-text);
      color: var(--accent-text);
      font-size: var(--fs-400);
      font-family: var(--ff-paragraph-3);

      &:focus,
      &:active {
        outline: 2px solid var(--primary-color);
        border-radius: 0px;
      }

      @media (max-width: 480px) {
        font-size: var(--fs-300);
      }
    }

    & button {
      padding: 0.5rem;
      border: none;
      font-size: var(--fs-300);
      font-weight: 600;
      background-color: white;
      color: var(--accent-text);
      border: 1px solid var(--accent-text);
      box-shadow: -2px 2px 0px 0 var(--accent-text);
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .dialog-buttons-container {
    width: 100%;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 20px;

    @media (max-width: 480px) {
      gap: 15px;
    }
  }

  .default-button,
  .delete-button {
    width: 50%;
    padding: 0.5rem;
    border: none;
    font-size: var(--fs-300);
    font-weight: 600;
    background-color: white;
    color: var(--accent-text);
    border: 1px solid var(--accent-text);
    box-shadow: -2px 2px 0px 0 var(--accent-text);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }

  .close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 0px !important;
    height: 25px;
    width: 25px;
    top: 10px;
    right: 10px;
    border: none;
    font-size: var(--fs-300);
    font-weight: 600;
    background-color: white;
    color: var(--accent-text);
    border: 1px solid var(--accent-text);
    box-shadow: -2px 2px 0px 0 var(--accent-text);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 50%;
    &:hover {
      transform: scale(1.05);
    }
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--primary-color);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
