:root {
  --fs-xl: clamp(1.5rem, 10vw, 4rem);
  --fs-500: clamp(1.5rem, 5vw, 2rem);
  --fs-400: clamp(1rem, 5vw, 1.2rem);
  --fs-300: clamp(0.7rem, 5vw, 0.9rem);

  --primary-color: #29d4fe;
  --primary-color-pressed: #20aacd;
  --secondary-color: #ffcd28;
  --secondary-color-pressed: #e2b521;
  --accent-color: #e13c3f;
  --accent-color-2: #ef780b;
  --accent-color-3: #aa61a4;
  --light-bg: #e0f0f8;
  --accent-text: #175168;
  --accent-text-2: #103a44;
  --success-color: #14a54b;

  --ff-heading: "Bangers", sans-serif;
  --ff-heading-2: "Hauracherell NC", sans-serif;
  --ff-paragraph: "Open Sans", sans-serif;
  --ff-paragraph-2: "Chivo", sans-serif;
  --ff-paragraph-3: "Gudea", sans-serif;

  --swiper-theme-color: var(--primary-color);
}

* {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: 0;
  font-family: var(--ff-paragraph-3);
  font-weight: 300;

  //* padding for fixed footer
  padding-bottom: 75px;

  //* padding for fixed header
  padding-top: 125px;

  @media (max-width: 835px) {
    padding-top: 150px;
  }
  @media (max-width: 480px) {
    padding-top: 115px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  text-transform: uppercase;
  color: black;
}

button {
  font-family: inherit;
}

.wrapper {
  margin: 0 auto;
  max-width: clamp(250px, 90vw, 1600px);
}

.hide {
  display: none;
}
